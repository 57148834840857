import styled, { css } from 'styled-components';

export const Heading = styled.h1(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-size: 32px;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 19px;
    color: #000000;
    max-width: 290px;
    letter-spacing: -0.025em;
    text-align: center;

    ${tablet()} {
      font-size: 44px;
      margin-bottom: 19px;
      max-width: 600px;
    }

    ${laptop()} {
      font-size: 50px;
      max-width: 750px;
      text-align: left;
    }

    ${desktop()} {
      font-size: 55px;
      max-width: 790px;
    }
  `
);

export const StaticWord = styled.span(
  () => css`
    position: relative;
    z-index: 4;
  `
);
