import React from 'react';
import styled, { css } from 'styled-components';
import Image from '@uc-common/image';

const Container = styled.div(
  ({ theme: { tablet } }) => css`
    --container-max-width: 900px;

    position: relative;
    overflow: hidden;
    width: calc(100% + var(--container-gutter-x) * 2);
    margin-left: calc(var(--container-gutter-x) * -1);
    margin-top: 60px;
    margin-bottom: 60px;
    max-width: var(--container-max-width);

    ${tablet()} {
      margin: 40px auto;
      overflow: visible;
      width: 100%;
    }
  `
);

const Text = styled.span(
  ({ theme: { laptop } }) => css`
    font-weight: 700;
    font-size: 32px;
    line-height: 1.2;
    color: #fff;
    display: grid;
    text-align: center;

    small {
      font-weight: 500;
      font-size: 12px;
      line-height: 2;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #bbc4d2;
    }

    ${laptop()} {
      font-size: 36px;

      small {
        font-size: 14px;
      }
    }
  `
);

const StyledImage = styled(Image)(
  ({ theme: { tablet } }) => css`
    width: 138%;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    transform: translateY(-50%) translateX(-50%);

    ${tablet()} {
      width: 100%;
      left: auto;
      top: auto;
      transform: none;
      position: relative;
      max-width: calc(var(--container-max-width) - 48px);
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  `
);

const Content = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    justify-content: center;
    grid-gap: 30px;
    position: relative;

    ${tablet()} {
      grid-auto-flow: column;
      justify-content: space-between;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      left: 0;
    }
  `
);

export const CDNMap = () => (
  <Container>
    <StyledImage
      alt="The Uploadcare Smart CDN is rock-solid and lightning-fast in both directions"
      src="https://ucarecdn.com/6cd47cdf-7dfc-4eea-af2a-63a4bd6db0e6/cdncoveragemap.svg"
    />
    <Content>
      <Text>
        325,000+
        <small>nodes worldwide</small>
      </Text>
      <Text>
        3<small>cdn providers</small>
      </Text>
      <Text>
        135<small>countries</small>
      </Text>
      <Text>
        99,99%
        <small>guaranteed uptime</small>
      </Text>
    </Content>
  </Container>
);
