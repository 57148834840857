import React from 'react';
import { Container, Icon, Text, Title } from './Feature.styles';

export const FeatureCard = ({ title, text, icon }) => (
  <Container>
    {icon && <Icon as={icon} />}
    <Title>{title}</Title>
    <Text>{text}</Text>
  </Container>
);
