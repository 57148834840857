import styled, { css } from 'styled-components';

import { CDNImageHint, CDNImageHintContainer } from './styled-components';

export const CDNImageDetalizationCDNImageHintVerticalLine = styled.i`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: calc(100% - 10px);
  width: 1px;
  background-color: var(--line-color);

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: -8px;
    width: 8px;
    height: 1px;
    background-color: var(--line-color);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
`;

export const CDNImageDetalizationHintContainer = styled(CDNImageHintContainer)`
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CDNImageDetalizationHint = styled(CDNImageHint)(
  ({ theme: { laptop } }) => css`
    position: relative;
    margin-left: 18px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -18px;
      margin: auto;
      width: 18px;
      height: 1px;
      background-color: var(--line-color);
    }

    ${laptop()} {
      margin-left: 60px;

      &::before {
        left: -60px;
        width: 60px;
      }
    }
  `
);

export const CDNImageDetalizationOperationsHintContainer = styled(
  CDNImageDetalizationHintContainer
)(
  ({ theme: { laptop } }) => css`
    --line-color: #3d2073;
    --background-color: rgba(42, 15, 91, 0.7);
    --text-color: #b392f0;

    ${/* sc-selector */ CDNImageDetalizationHint} {
      margin-left: 14px;

      &:before {
        left: -14px;
        width: 14px;
      }

      ${laptop()} {
        margin-left: 67px;

        &:before {
          left: -67px;
          width: 67px;
        }
      }
    }
  `
);

export const CDNImageDetalizationFileNameHintContainer = styled(CDNImageDetalizationHintContainer)(
  ({ theme: { laptop } }) => css`
    --line-color: #244f42;
    --background-color: #112f26;
    --text-color: #69e3bf;

    ${/* sc-selector */ CDNImageDetalizationHint} {
      margin-left: 196px;

      &:before {
        left: -196px;
        width: 196px;
      }

      ${laptop()} {
        margin-left: 260px;

        &:before {
          left: -260px;
          width: 260px;
        }
      }
    }
  `
);
