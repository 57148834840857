import React from 'react';
import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { ButtonTypeEnum } from '@uc-common/button';

import { SubText, SubTitle, CTAButton } from '../Typography';
import { Step } from '../Step';
import { ReviewCard } from '../ReviewCard';
import { DarkSection } from '../Section';
import { FeatureAccordion } from '../FeatureAccordion';
import { FeaturesWithReview } from '../FeaturesWithReview';
import { UploaderPreview } from './UploaderPreview';
import { Integrations } from './Integrations';
import { Link } from '../../Link';

const StyledSection = styled(DarkSection)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    overflow: hidden;
    position: relative;
    padding-top: 90px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 130px;
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.76) 0%,
          rgba(0, 0, 0, 0.85) 34.38%,
          #000000 100%
        ),
        linear-gradient(90deg, #3046ea 0%, #fbb5f0 56.25%, #f9e5b0 100%);
    }

    ${tablet()} {
      padding-top: 115px;
    }

    ${laptop()} {
      padding-top: 150px;
    }

    ${desktop()} {
      padding-top: 160px;
    }
  `
);

export const Uploading = ({ ppc = false }) => (
  <StyledSection>
    <Container>
      {ppc ? (
        <>
          <SubTitle>No more failed&nbsp;uploads</SubTitle>
          <SubText>
            <em>
              Receive files from your users with a clean, lightweight and easy-to-integrate widget.
            </em>
          </SubText>
        </>
      ) : (
        <>
          <Step step="1">uploading</Step>
          <SubTitle>Enable obsessively friendly&nbsp;file&nbsp;uploads</SubTitle>
          <SubText>
            <em>
              Friendly to users and developers alike. Our HTML5 uploading widget and underlying API
              fit your stack, tick every box, and look great on any device.
            </em>
          </SubText>

          <UploaderPreview />
        </>
      )}

      <Integrations />

      <FeaturesWithReview>
        <FeatureAccordion
          data={[
            {
              title: 'Lightweight',
              defaultExpanded: true,
              content:
                'Responsive HTML5 widget with built-in image editor. Light on resources and developer time.',
              icon: 'https://ucarecdn.com/3bf9c0df-ad1b-45d0-899e-c26181be18ae/lightweight.svg',
            },
            {
              title: 'Lightspeed',
              defaultExpanded: true,
              content:
                'Accelerated uploads to storage via our global CDN. Multipart, resumable, up to 5TB per file.',
              icon: 'https://ucarecdn.com/8048ad3b-6edd-47c0-9682-a5bac5015c7f/lightspeed.svg',
            },
            {
              title: 'Customizable',
              defaultExpanded: true,
              content:
                'Set up colors, accents and dark mode, or go deep with CSS & JS. Enable up to 12 file sources.',
              icon: 'https://ucarecdn.com/776ea5ad-76ed-401d-b59f-7c1330b1d37c/customizable.svg',
            },
          ]}
        />

        <ReviewCard
          quote="It's the most painless, enjoyable flow out there. Awesome product. We all love Uploadcare dearly."
          logoAlt="mozilla"
          logoSrc="https://ucarecdn.com/aac7854f-3fe2-47c1-b23d-61d9d5ae4408/mozilla.svg"
          avatarSrc="https://ucarecdn.com/709c9ec2-01ec-41a1-a4fe-9d601d665564/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
          avatarAlt="Chris Van Wiemeersch | DX Engineer"
          withBackground={false}
        />
      </FeaturesWithReview>

      {ppc && (
        <CTAButton
          forwardedAs={Link}
          to="/accounts/signup/"
          type={ButtonTypeEnum.PRIMARY}
          data-analytics="btn_signup_sem_3"
        >
          Try now
        </CTAButton>
      )}
    </Container>
  </StyledSection>
);
