import React from 'react';
import styled, { css } from 'styled-components';
import { Accordion } from './Accordion';

const Container = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-gap: 26px;
    align-self: flex-start;

    ${tablet()} {
      grid-gap: 23px;
    }
  `
);

export const FeatureAccordion = ({ data }) => (
  <Container>
    {data.map(({ title, content, icon, defaultExpanded }, index) => (
      <Accordion
        key={index}
        title={title}
        content={content}
        defaultExpanded={defaultExpanded}
        icon={icon}
      />
    ))}
  </Container>
);
