import styled, { css } from 'styled-components';

export const Container = styled.li(
  ({ theme: { laptop, desktop } }) => css`
    --container-padding: 25px;

    border: 1px solid #21262f;
    border-radius: 12px;
    padding: var(--container-padding);
    list-style-type: none;

    ${laptop()} {
      --container-padding: 30px;
    }

    ${desktop()} {
      --container-padding: 40px;
    }
  `
);

export const Icon = styled.i`
  margin-top: calc((var(--container-padding) + 30px) * -1);
  margin-bottom: 27px;
  width: 80px;
  height: 80px;
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 12px;
`;

export const Text = styled.p(
  ({ theme: { desktop } }) => css`
    font-size: 15px;
    line-height: 160%;
    color: #bbc4d2;

    ${desktop()} {
      font-size: 16px;
    }
  `
);
