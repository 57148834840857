import Image from '@uc-common/image';
import styled, { css } from 'styled-components';
import { Avatar } from '../Avatar';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';

export const StyledAvatar = styled(Avatar)`
  transition: opacity 0.2s;
`;

export const FooterText = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  transition: opacity 0.2s;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  color: #455059;
  transition: color 0.2s;
`;

export const Container = styled.div(
  ({ to, $withBackground = true, theme: { laptop } }) => css`
    max-width: 404px;
    display: block;
    padding: 30px 20px;

    ${$withBackground &&
    css`
      border-radius: 12px;
      box-sizing: content-box;
      background-color: #101214;
      border: 1px solid rgba(255, 255, 255, 0.08);
    `}

    ${to &&
    css`
      text-decoration: none;

      &:hover {
        ${StyledAvatar} {
          opacity: 0;
        }

        ${FooterText} {
          opacity: 1;
        }

        ${StyledArrowIcon} {
          color: #fff;
        }
      }
    `}

    ${laptop()} {
      padding: 40px;
    }
  `
);

export const Header = styled.div(
  ({ theme: { tablet, laptop } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;

    ${tablet()} {
      margin-bottom: 20px;
    }

    ${laptop()} {
      margin-bottom: 22px;
    }
  `
);

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  position: relative;
`;

export const Logo = styled(Image)`
  max-height: 32px;
`;

export const Label = styled.span(
  ({ theme: { tablet } }) => css`
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #ffffff;
    padding: 3px 7px;
    background: #22262b;
    border-radius: 8px;

    ${tablet()} {
      font-size: 16px;
      padding: 4px 14px;
    }
  `
);

export const Blockquote = styled.blockquote(
  ({ theme: { tablet, laptop, desktop } }) => css`
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    margin-bottom: 20px;

    ${tablet()} {
      font-weight: 500;
      font-size: 18px;
      line-height: 160%;
    }

    ${laptop()} {
      margin-bottom: 30px;
    }

    ${desktop()} {
      font-size: 20px;
    }
  `
);
