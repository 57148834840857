import React from 'react';
import { Container, Content, Icon, Title } from './Accordion.styles';
import { useAccordion } from '../../../../hooks/useAccordion';

export const Accordion = ({ title, content, icon, defaultExpanded }) => {
  const { ref, height, isRender, isVisible, toggleExpanded, onAnimationEnd } =
    useAccordion(defaultExpanded);

  return (
    <Container $isExpanded={isVisible} $height={height}>
      <Title onClick={toggleExpanded}>
        <Icon src={icon} alt="" />
        {title}
      </Title>

      {isRender && (
        <Content ref={ref} onTransitionEnd={onAnimationEnd}>
          {content}
        </Content>
      )}
    </Container>
  );
};
