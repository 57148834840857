import React from 'react';

import {
  CDNImageDetalizationHint,
  CDNImageDetalizationHintContainer,
  CDNImageDetalizationCDNImageHintVerticalLine,
  CDNImageDetalizationFileNameHintContainer,
  CDNImageDetalizationOperationsHintContainer,
} from './styles/CDNImageDetails.styles';
import {
  CDNImageAttribute,
  CDNImageCodeBlock,
  CDNImageCommonBlocksContainer,
  CDNImageContainer,
  CDNImageFileName,
  CDNImageHintText,
  CDNImageOperationName,
  CDNImageOperationValue,
  CDNImageTags,
  CDNImageUUID,
} from './styles/styled-components';

const maskUuid = (uuid) => [uuid.slice(0, 4), '...', uuid.slice(-4)].join('');

export const CDNImageDetails = ({ className, src, name = 'my-image' }) => {
  const url = new URL(src);
  const uuid = url.pathname.match(/\/(.*?)\//)[1];
  const operation = url.pathname.split(uuid)[1];

  return (
    <CDNImageContainer className={className}>
      <CDNImageTags>{'<img src='}</CDNImageTags>
      <CDNImageAttribute>
        <CDNImageCommonBlocksContainer>
          <CDNImageCodeBlock>
            <CDNImageTags>&#39;</CDNImageTags>
            {url.origin}/<CDNImageUUID>{maskUuid(uuid)}</CDNImageUUID>/
          </CDNImageCodeBlock>

          <CDNImageDetalizationHintContainer>
            <CDNImageDetalizationHint>
              <CDNImageHintText>Image ID</CDNImageHintText>
            </CDNImageDetalizationHint>
          </CDNImageDetalizationHintContainer>
        </CDNImageCommonBlocksContainer>

        {Boolean(operation) && (
          <CDNImageCommonBlocksContainer>
            {operation?.split('/-').map((part) => {
              const parts = part.split('/').filter(Boolean);

              return (
                <CDNImageCodeBlock key={part}>
                  {parts.map((value, index) => {
                    if (index === 0) {
                      return (
                        <React.Fragment key={index}>
                          -/
                          <CDNImageOperationName>{value}</CDNImageOperationName>/
                        </React.Fragment>
                      );
                    }

                    return (
                      <React.Fragment key={index}>
                        <CDNImageOperationValue>{value}</CDNImageOperationValue>/
                      </React.Fragment>
                    );
                  })}
                </CDNImageCodeBlock>
              );
            })}

            <CDNImageDetalizationOperationsHintContainer>
              <CDNImageDetalizationCDNImageHintVerticalLine />
              <CDNImageDetalizationHint>
                <CDNImageHintText>Chain of transformations</CDNImageHintText>
              </CDNImageDetalizationHint>
            </CDNImageDetalizationOperationsHintContainer>
          </CDNImageCommonBlocksContainer>
        )}

        <CDNImageCommonBlocksContainer>
          <CDNImageCodeBlock>
            <CDNImageFileName>{name}</CDNImageFileName>
            <CDNImageTags>&#39;</CDNImageTags>
          </CDNImageCodeBlock>

          <CDNImageDetalizationFileNameHintContainer>
            <CDNImageDetalizationHint>
              <CDNImageHintText>File name</CDNImageHintText>
            </CDNImageDetalizationHint>
          </CDNImageDetalizationFileNameHintContainer>
        </CDNImageCommonBlocksContainer>
      </CDNImageAttribute>
      <CDNImageTags>{'/>'}</CDNImageTags>
    </CDNImageContainer>
  );
};
