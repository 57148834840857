import { useState, useEffect } from 'react';

// example: {isRender && <Component isVisible={isVisible} onAnimationEnd|onTransitionEnd={onAnimationEnd} />}
export const useFadeIn = (isShow) => {
  const [isRender, setIsRender] = useState(isShow);
  const [isVisible, setIsVisible] = useState(isShow);

  useEffect(() => {
    if (isShow) {
      setIsRender(true);
    } else {
      setIsVisible(false);
    }
  }, [isShow]);

  useEffect(() => {
    if (isRender) {
      setIsVisible(true);
    }
  }, [isRender]);

  const onAnimationEnd = () => {
    if (!isVisible) {
      setIsRender(false);
    }
  };

  return { isRender, isVisible, onAnimationEnd };
};
