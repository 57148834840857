import React from 'react';
import styled, { css } from 'styled-components';
import grprSrc from './assets/gdpr.svg';
import hipaaSrc from './assets/hipaa.svg';
import socSrc from './assets/soc.svg';

const Container = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    display: grid;
    justify-content: space-between;
    grid-auto-flow: column;
    grid-gap: 25px;
    align-items: center;
    grid-template-columns: repeat(3, minmax(min-content, 60px));

    ${laptop()} {
      justify-content: flex-end;
      grid-gap: 50px;
    }

    ${desktop()} {
      grid-gap: 60px;
    }

    img {
      height: 56px;

      ${tablet()} {
        height: 68px;
      }
      ${desktop()} {
        height: 74px;
      }
    }
  `
);

export const Logos = () => (
  <Container>
    <img src={socSrc} alt="" />
    <img src={grprSrc} alt="" />
    <img src={hipaaSrc} alt="" />
  </Container>
);
