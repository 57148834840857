import { useEffect, useRef, useState } from 'react';
import { useCurrentBreakpoint } from '@uc-common/use-current-breakpoint';
import { useFadeIn } from './useFadeIn';

export const useAccordion = (defaultExpanded) => {
  const ref = useRef(null);
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [height, setHeight] = useState(defaultExpanded ? 'auto' : '0px');
  const { isRender, isVisible, onAnimationEnd } = useFadeIn(isExpanded);
  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const breakpoint = useCurrentBreakpoint();

  useEffect(() => {
    if (isRender) {
      setHeight(`${ref.current.scrollHeight}px`);
    }
  }, [isRender]);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = 'auto';
      setHeight(`${ref.current?.scrollHeight}px`);
      ref.current.style.height = '';
    }
  }, [breakpoint]);

  return {
    ref,
    height,
    isRender,
    isVisible,
    toggleExpanded,
    onAnimationEnd,
  };
};
