import styled, { css } from 'styled-components';
import { ReviewCard } from '../../ReviewCard';

export const Container = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    --container-top-padding: 40px;
    --container-right-padding: 30px;
    --container-bottom-padding: 30px;
    --container-left-padding: 30px;

    border: 1px solid #21262f;
    border-radius: 12px;
    padding: var(--container-top-padding) var(--container-right-padding)
      var(--container-bottom-padding) var(--container-left-padding);
    /* url without /quality/, because quality operation increase file size */
    background: no-repeat 0 0
      url('https://ucarecdn.com/9c4dca01-7a8b-4fda-9e31-1b4d2ca64ee3/bggradient.svg');
    background-size: 100%;
    margin-top: 60px;

    ${tablet()} {
      padding-bottom: 40px;
      background-size: 50%;
    }

    ${laptop()} {
      --container-right-padding: 40px;
      --container-bottom-padding: 40px;
      --container-left-padding: 40px;
    }

    ${desktop()} {
      --container-top-padding: 70px;
      --container-right-padding: 70px;
      --container-bottom-padding: 52px;
      --container-left-padding: 70px;
      margin-top: 100px;
    }
  `
);

export const Header = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;

    ${tablet()} {
      grid-auto-flow: column;
      grid-gap: 30px;
      margin-bottom: 40px;
    }
  `
);

export const Footer = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    padding-top: 30px;
    grid-gap: 30px;

    ${tablet()} {
      grid-auto-flow: column;
      align-items: center;
      border-top: 1px solid #21262f;
    }
  `
);

export const StyledReviewCard = styled(ReviewCard)(
  ({ theme: { tablet } }) => css`
    max-width: none;
    margin-left: calc(var(--container-left-padding) / 2 * -1);
    margin-right: calc(var(--container-right-padding) / 2 * -1);
    margin-top: 40px;

    ${tablet()} {
      width: auto;
      margin-top: calc((var(--container-top-padding) + 16px) * -1);
      margin-left: 0;
    }
  `
);
