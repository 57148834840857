import React from 'react';
import { getSrc } from '@uc-common/image/utils';
import { Container, Image, StyledCDNImageDetails } from './CDNImageDetailsPreview.styles';

export const exampleImageData = {
  uuid: '06933f0d-5f31-425d-a5b8-6c5c2cdaaae6',
  options: {
    resize: '1200x',
    blur_region: 'faces',
    filter: 'jadis',
    format: 'auto',
    quality: 'smart',
  },
  alt: 'People in office',
};

export const CDNImageDetailsPreview = ({ uuid, options, alt, revert }) => {
  const src = getSrc({ uuid, options });

  return (
    <Container $revert={revert}>
      <StyledCDNImageDetails src={src} />
      <Image uuid={uuid} options={options} alt={alt} />
    </Container>
  );
};
