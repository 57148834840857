import styled, { css } from 'styled-components';
import BitImage from '@uc-common/image';
import { ReactComponent as BgIcon } from './assets/bg.svg';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';

export const Container = styled.div(
  ({ theme: { tablet } }) => css`
    position: relative;
    margin-top: 50px;

    ${tablet()} {
      margin-top: 70px;
    }
  `
);

export const Image = styled(BitImage)(
  ({ $show }) => css`
    max-width: 520px;
    width: 100%;
    opacity: 0;
    transform: translateY(15%);
    transition:
      opacity 0.8s,
      transform 0.8s;
    display: block;
    margin: 0 auto;
    border: 0.5px solid #303132;
    box-shadow:
      0px 4px 4px rgba(0, 0, 0, 0.25),
      0px 6px 40px rgba(0, 0, 0, 0.4),
      inset 0px 0.5px 0px rgba(255, 255, 255, 0.15);
    border-radius: 8px;

    ${$show &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}
  `
);

export const Button = styled.button(
  ({ $hide }) => css`
    appearance: none;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
    width: 200px;
    height: 44px;
    padding: 0 20px;
    border: none;
    background: linear-gradient(180deg, #282b2d 0%, #141823 111.36%);
    box-shadow:
      0px 1px 1px rgba(0, 0, 0, 0.4),
      0px 2px 8px rgba(0, 0, 0, 0.3),
      inset 0px 1px 0px rgba(255, 255, 255, 0.2);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s;

    ${$hide &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  `
);

export const BackgroundGradient = styled(BgIcon)`
  position: absolute;
  right: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  transform-origin: left;
  transform: scale(1.5) translateX(-50%);
`;

export const StyledButtonIcon = styled(ArrowIcon)`
  margin-right: 25px;
  opacity: 0.8;
`;
