import React, { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import {
  BackgroundGradient,
  Button,
  Container,
  Image,
  StyledButtonIcon,
} from './UploaderPreview.styles';
import { Link } from '../../../Link';

export const UploaderPreview = () => {
  const [isVisibleImg, setIsVisibleImg] = useState(false);
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '-50% 0% -50% 0%',
    threshold: 0,
  });
  const isIntersecting = intersection?.isIntersecting;

  useEffect(() => {
    if (isIntersecting && !isVisibleImg) {
      setIsVisibleImg(true);
    }
  }, [isIntersecting, isVisibleImg]);

  return (
    <Container ref={intersectionRef}>
      <BackgroundGradient />
      <Link to="/products/file-uploader/">
        <Image
          $show={isVisibleImg}
          src="https://ucarecdn.com/80c18b29-f0ec-440b-92d8-70f5ef6add4a/file-uploader.png"
          alt="Solution for fast and secure uploading"
        />
      </Link>
      <Button $hide={isVisibleImg}>
        <StyledButtonIcon />
        Choose files
      </Button>
    </Container>
  );
};
