import { ReactComponent as RecognitionIcon } from './assets/recognition.svg';
import { ReactComponent as ContentInfoIcon } from './assets/contentinfo.svg';
import { ReactComponent as BackgroundIcon } from './assets/background.svg';
import { ReactComponent as DocsIcon } from './assets/docs.svg';
import { ReactComponent as MalwareIcon } from './assets/malware.svg';
import { ReactComponent as MetaDataIcon } from './assets/metadata.svg';
import { ReactComponent as SizeTypeIcon } from './assets/sizetype.svg';
import { ReactComponent as WebhooksIcon } from './assets/webhooks.svg';
import { ReactComponent as VideoIcon } from './assets/video.svg';
import { ReactComponent as ImageOptimizationIcon } from './assets/image-optimization.svg';
import { ReactComponent as ImageTransformationsIcon } from './assets/image-transformations.svg';
import { ReactComponent as SmartCompressionIcon } from './assets/smart-compression.svg';

export const data = [
  {
    title: 'Image optimization',
    text: 'Reduce image size by up to 80% for better UX and SEO.',
    icon: ImageOptimizationIcon,
  },
  {
    title: 'AVIF and WEBP on autopilot',
    text: 'Automatically convert images to the most efficient next-gen formats.',
    icon: SmartCompressionIcon,
  },
  {
    title: 'Image transformations',
    text: 'Crop, resize and adjust colors. All with simple URL commands.',
    icon: ImageTransformationsIcon,
  },
  {
    title: 'AI object tagging',
    text: 'Gather accurate recognition data for people, objects and content.',
    icon: RecognitionIcon,
  },
  {
    title: 'Content info',
    text: 'Detailed media insights: MIME type, size, resolution, format & more.',
    icon: ContentInfoIcon,
  },
  {
    title: 'Size and type filtering',
    text: 'Validate and filter all uploaded files server-side for safer, easier handling.',
    icon: SizeTypeIcon,
  },
  {
    title: 'Malware scanner',
    text: 'Automatically prevent uploads of infected or malicious files.',
    icon: MalwareIcon,
  },
  {
    title: 'Webhooks',
    text: 'Build custom file uploading and management workflows.',
    icon: WebhooksIcon,
  },
  {
    title: 'Custom metadata',
    text: 'Add custom info for stored files: order numbers, item IDs, user names...',
    icon: MetaDataIcon,
  },
  {
    title: 'Video processing',
    text: 'Encode, convert, resize, generate thumbnails – for videos and GIFs.',
    icon: VideoIcon,
  },
  {
    title: 'Document conversion',
    text: 'Accept, process and convert all modern office file formats.',
    icon: DocsIcon,
  },
  {
    title: 'Background removal',
    text: 'Isolate key content in any photo, accurately and 100% automatically.',
    icon: BackgroundIcon,
  },
];
