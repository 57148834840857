import React from 'react';
import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { ButtonTypeEnum } from '@uc-common/button';
import { BuzzLogoIdEnum, logos } from '@uc-common/buzz-logo';
import { Step } from '../Step';
import { SubText, SubTitle, CTAButton } from '../Typography';
import { ReviewCard } from '../ReviewCard';
import { DarkSection } from '../Section';
import { FeatureAccordion } from '../FeatureAccordion';
import { CDNImageDetailsPreview, exampleImageData } from '../../CDNImageDetailsPreview';
import { FeaturesWithReview } from '../FeaturesWithReview';
import { Link } from '../../Link';

const StyledSubTitle = styled(SubTitle)(
  ({ theme: { tablet } }) => css`
    ${tablet()} {
      max-width: 600px;
    }
  `
);

export const SmartCDN = ({ ppc = false }) => (
  <DarkSection>
    <Container>
      {ppc ? (
        <>
          <StyledSubTitle>
            Process media on&nbsp;the&nbsp;fly with an intuitive URL&nbsp;API
          </StyledSubTitle>
          <SubText>
            <em>
              Optimize images with Uploadcare CDN before they’re sent to your app or website. Simply
              change URL parameters and hack away: the silicon for transformations is on us.
            </em>
          </SubText>

          <CTAButton
            forwardedAs={Link}
            to="/accounts/signup/"
            type={ButtonTypeEnum.PRIMARY}
            data-analytics="btn_signup_sem_4"
          >
            Get started
          </CTAButton>
        </>
      ) : (
        <>
          <Step step="3">smart cdn</Step>
          <StyledSubTitle>
            Process media on&nbsp;the&nbsp;fly right before&nbsp;delivery&nbsp;time
          </StyledSubTitle>
          <SubText>
            <em>
              Optimize images on our CDN before they’re sent to your app or website. Simply change
              URL parameters and hack away: the silicon for transformations is on us.
            </em>
          </SubText>
        </>
      )}

      <CDNImageDetailsPreview
        uuid={exampleImageData.uuid}
        alt={exampleImageData.alt}
        options={{ ...exampleImageData.options, mirror: '' }}
      />

      <FeaturesWithReview>
        <FeatureAccordion
          data={[
            {
              title: 'Chain 40+ operations',
              defaultExpanded: true,
              content:
                'Add intuitive parameters to your asset’s URL to apply multiple transformations on the spot.',
              icon: 'https://ucarecdn.com/85fd08cf-464d-419b-8a7f-ab8d27648bc3/chainoperations.svg',
            },
            {
              title: 'Automate image ops',
              defaultExpanded: true,
              content:
                'Smart compression, backgrounds, cropping and more. Powered by AI and the world’s fastest image resizing algo.',
              icon: 'https://ucarecdn.com/ffd5f4ee-959d-45db-9b02-4582a2bb91f6/automateimageops.svg',
            },
            {
              title: 'Save bandwidth',
              defaultExpanded: true,
              content:
                'Deliver optimized media that load much faster, while offloading resource-intensive transformations to our CDN.',
              icon: 'https://ucarecdn.com/df80a13c-2b65-4626-89f1-8dc3b8b4ac74/savebandwidth.svg',
            },
          ]}
        />

        <ReviewCard
          quote="In-house file infrastructure? No thank you. With Uploadcare, we save 40 hours of our developers time per week and decrease the total cost of ownership (TCO) on infrastructure development. More to ask?"
          logoAlt="Chatra"
          logoSrc={logos[BuzzLogoIdEnum.CHATRA].src}
          avatarSrc="https://ucarecdn.com/2c2ad30d-b5cf-4c08-bcd4-6f088424844d/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
          avatarAlt="Yakov Karda | CEO & Co-founder"
          withBackground={false}
        />
      </FeaturesWithReview>
    </Container>
  </DarkSection>
);
