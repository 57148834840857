import React from 'react';
import styled, { css } from 'styled-components';

const Label = styled.span(
  ({ theme: { laptop, desktop } }) => css`
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
    color: #a48dff;
    display: inline-flex;
    flex-direction: column;
    padding: 10px 0;
    margin-right: 30px;

    small {
      font-weight: 500;
      font-size: 11px;
      line-height: 2.1;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #bbc4d2;
      margin-top: 2px;
    }

    ${laptop()} {
      font-size: 34px;

      small {
        font-size: 12px;
      }
    }

    ${desktop()} {
      small {
        font-size: 13px;
      }
    }
  `
);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const Labels = () => (
  <Container>
    <Label>
      25B+
      <small>requests per week</small>
    </Label>

    <Label>
      99.99%
      <small>guaranteed uptime</small>
    </Label>
  </Container>
);
