import React from 'react';
import styled, { css } from 'styled-components';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import { Link } from '../../../../Link';

const Title = styled.h3(
  ({ theme: { tablet, laptop } }) => css`
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #ffffff;
    margin-bottom: 15px;

    ${tablet()} {
      font-size: 28px;
    }

    ${laptop()} {
      font-size: 36px;
    }
  `
);

const Text = styled.p(
  ({ theme: { tablet } }) => css`
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #bbc4d2;
    margin: 0 0 20px;

    ${tablet()} {
      margin-bottom: 30px;
    }
  `
);

export const TextBlock = () => (
  <div>
    <Title>Enterprise ready</Title>
    <Text>
      Meet your enterprise volume & compliance needs with an ever-growing bundle of add-ons &
      features.
    </Text>
    <Button
      as={Link}
      to="/schedule-demo/"
      type={ButtonTypeEnum.PRIMARY}
      data-analytics="btn_schedule-demo_0"
    >
      Contact sales
    </Button>
  </div>
);
