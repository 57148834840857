import styled, { css } from 'styled-components';

export const FeaturesWithReview = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    display: grid;
    grid-gap: 60px;
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;

    ${tablet()} {
      grid-auto-flow: column;
      grid-gap: 80px;
      grid-auto-columns: 42.5%;
      justify-content: space-between;
      margin-top: 74px;
    }

    ${laptop()} {
      grid-gap: 109px;
      grid-auto-columns: 425px;
    }

    ${desktop()} {
      margin-top: 100px;
    }
  `
);
