import React from 'react';
import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { PlanCardFree } from '../../PlanCardFree';
import { Review } from './Review';
import { DarkSection } from '../Section';
import { CDNMap } from './CDNMap';
import { SubText, SubTitle } from '../Typography';

const StyledSection = styled(DarkSection)(
  ({ theme: { tablet, laptop } }) => css`
    padding-bottom: 120px;

    ${tablet()} {
      padding-bottom: 140px;
    }

    ${laptop()} {
      padding-bottom: 170px;
    }
  `
);

export const LastScreen = () => (
  <StyledSection>
    <Container>
      <SubTitle>The infra comes with the territory</SubTitle>
      <SubText>
        <em>
          The Uploadcare Smart CDN is rock-solid and lightning-fast in both directions. Secure
          uploads even on spotty connections, and deliver media anywhere as if it were cached next
          door. With no maintenance required.
        </em>
      </SubText>
      <CDNMap />
      <PlanCardFree btnSignUpProps={{ 'data-analytics': 'btn_signup_1' }} />
      <Review />
    </Container>
  </StyledSection>
);
