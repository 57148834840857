import React from 'react';
import { Heading, StaticWord } from './Title.styles';

export const Title = ({ ppc = false }) => (
  <Heading>
    <StaticWord>
      {ppc ? (
        <>File&nbsp;infrastructure for modern web&nbsp;apps by Uploadcare</>
      ) : (
        <>Build file handling in minutes</>
      )}
    </StaticWord>
  </Heading>
);
