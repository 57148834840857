import React from 'react';
import styled, { css } from 'styled-components';
import { FeatureCard } from '../FeatureCard';
import { data } from './data';

const Container = styled.ul(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-gap: 45px;
    margin-top: 110px;

    ${tablet()} {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 50px 7px;
      margin-top: 130px;
    }

    ${tablet()} {
      grid-gap: 60px 8px;
    }
  `
);

export const Features = () => (
  <Container>
    {data.map(({ title, text, icon }, index) => (
      <FeatureCard key={index} title={title} text={text} icon={icon} />
    ))}
  </Container>
);
