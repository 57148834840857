import styled, { css } from 'styled-components';
import arrowSrc from './assets/arrow.svg';

export const Title = styled.p(
  ({ theme: { tablet } }) => css`
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: -0.025em;
    color: #ffffff;
    display: grid;
    grid-gap: 13px;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: min-content;
    transition: color 0.2s;
    padding-left: var(--content-left-padding);
    position: relative;

    &::before {
      content: '';
      width: 12px;
      height: 6px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: no-repeat url(${arrowSrc}) 0 0;
      background-size: 100% 100%;
      transition: transform 0.2s;
    }

    ${tablet()} {
      font-size: 22px;
    }
  `
);

export const Content = styled.div(
  ({ theme: { tablet, desktop } }) => css`
    transition: all 0.2s;
    font-size: 15px;
    line-height: 160%;
    color: #bbc4d2;
    margin-top: 26px;

    ${tablet()} {
      font-size: 16px;
      margin-top: 20px;
      padding-left: var(--content-left-padding);
    }

    ${desktop()} {
      font-size: 18px;
    }
  `
);

export const Container = styled.div(
  ({ $isExpanded, $height }) => css`
    --content-left-padding: 25px;

    ${$isExpanded &&
    css`
      ${Content} {
        height: ${$height};
      }
    `}

    ${!$isExpanded &&
    css`
      ${Content} {
        height: 0;
        margin-top: 0;
        opacity: 0;
      }

      ${Title} {
        color: #bbc4d2;

        &::before {
          transform: translateY(-50%) rotate(-90deg);
        }
      }
    `}
  `
);

export const Icon = styled.img`
  max-width: 25px;
  max-height: 25px;
`;
