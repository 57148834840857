import React from 'react';
import { Container, Footer, Header, StyledReviewCard } from './Enterprise.styles';
import { Logos } from './Logos';
import { Labels } from './Labels';
import { TextBlock } from './TextBlock';

export const Enterprise = () => (
  <Container>
    <Header>
      <TextBlock />
      <StyledReviewCard
        to="/customers/shogun/"
        quote="For us to build the functionality we use, it would probably take a team of 3 or 4 developers several months. Using Uploadcare, we've basically taken a shortcut."
        logoAlt="shogun"
        logoSrc="https://ucarecdn.com/54d94e16-3546-44d6-8cec-6a4995ad6b52/shogunbw.svg"
        label="Saved $200,000"
        avatarSrc="https://ucarecdn.com/f040562b-f862-43e4-88f9-abc172588e37/-/scale_crop/88x88/center/-/format/auto/-/quality/normal/"
        avatarAlt="Finbarr Taylor | CEO & Co-founder"
      />
    </Header>

    <Footer>
      <Labels />
      <Logos />
    </Footer>
  </Container>
);
