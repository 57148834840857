import styled, { css } from 'styled-components';
import BitImage from '@uc-common/image';
import { CDNImageDetails } from '../CDNImageDetails';

export const Image = styled(BitImage)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    width: calc(100% + var(--container-gutter-x) * 2);
    margin-left: calc(var(--container-gutter-x) * -1);

    ${tablet()} {
      align-self: flex-end;
      width: 522px;
      margin-left: 0;
      border-radius: 12px;
    }

    ${laptop()} {
      width: 600px;
    }

    ${desktop()} {
      margin-left: 56px;
    }
  `
);

export const StyledCDNImageDetails = styled(CDNImageDetails)(
  ({ theme: { tablet, laptop } }) => css`
    margin-bottom: -30px;
    background: rgba(0, 0, 0, 0.8);
    border: 1px solid #21262f;
    backdrop-filter: blur(20px);
    border-radius: 12px;
    padding: 20px 0 20px 20px;

    ${tablet()} {
      margin-bottom: 0;
      margin-top: -112px;
      padding: 20px 30px;
      width: max-content;
    }

    ${laptop()} {
      margin-top: -320px;
      padding: 65px 68px 65px 47px;
    }
  `
);

export const Container = styled.div(
  ({ theme: { tablet, laptop, desktop }, $revert }) => css`
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;

    ${tablet()} {
      flex-direction: column-reverse;
      margin-top: 128px;
      max-width: 704px;
    }

    ${laptop()} {
      position: relative;
      margin-top: 68px;
      max-width: 936px;
    }

    ${desktop()} {
      max-width: 1010px;
    }

    ${$revert &&
    css`
      ${Image} {
        align-self: flex-start;

        ${desktop()} {
          margin-left: 0;
          margin-right: 56px;
        }
      }

      ${StyledCDNImageDetails} {
        align-self: flex-end;
      }
    `}
  `
);
