import React from 'react';
import styled from 'styled-components';
import { Container } from '@uc-common/container';
import { ButtonTypeEnum } from '@uc-common/button';

import { Step } from '../Step';
import { SubText, SubTitle, CTAButton } from '../Typography';
import { Features } from './Features';
import { DarkSection } from '../Section';
import { ApiExample } from './ApiExample';
import { Enterprise } from './Enterprise';
import { Link } from '../../Link';

const StyledSection = styled(DarkSection)`
  padding-top: 50px;
`;

export const Platform = ({ ppc = false }) => (
  <StyledSection>
    <Container>
      <ApiExample />

      {!ppc && <Step step="2">platform</Step>}

      <SubTitle>Handle all files&nbsp;smart &&nbsp;RESTfully</SubTitle>
      <SubText>
        <em>
          Ship faster with a complete file platform in your toolbelt. Use integrated apps to
          validate, manage and analyze files. Create custom workflows and process in batch via REST
          API.
        </em>
      </SubText>

      {ppc ? (
        <>
          <CTAButton
            forwardedAs={Link}
            to="/accounts/signup/"
            type={ButtonTypeEnum.PRIMARY}
            data-analytics="btn_signup_sem_1"
          >
            Get API keys
          </CTAButton>

          <Features />

          <CTAButton
            forwardedAs={Link}
            to="/accounts/signup/"
            type={ButtonTypeEnum.PRIMARY}
            data-analytics="btn_signup_sem_2"
          >
            Get started
          </CTAButton>
        </>
      ) : (
        <>
          <Features />
          <Enterprise />
        </>
      )}
    </Container>
  </StyledSection>
);
