import React from 'react';
import styled, { css } from 'styled-components';
import { Container } from '@uc-common/container';
import { ButtonTypeEnum } from '@uc-common/button';

import { Clients } from '~/src/components/Clients';
import { Link } from '../../Link';
import { SubText, CTAButton } from '../Typography';
import { Title } from './Title';
import { Section } from '../Section';

const StyledContainer = styled(Container)(
  ({ theme: { laptop } }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    video {
      max-width: 435px;
    }

    ${laptop()} {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 calc(var(--container-gutter-x) + 30px) 0 var(--container-gutter-x);
    }
  `
);

const StyledSection = styled(Section)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    padding-top: 60px;

    ${tablet()} {
      padding-top: 80px;
    }

    ${laptop()} {
      padding-top: 60px;
    }

    ${desktop()} {
      padding-top: 85px;
    }
  `
);

const StyledSubText = styled(SubText)(
  ({ theme: { tablet, laptop } }) => css`
    max-width: 540px;
    font-size: 18px;
    text-align: center;
    color: #5f6872;
    margin: 0;

    ${tablet()} {
      font-size: 20px;
    }

    ${laptop()} {
      text-align: left;
    }
  `
);

const StyledClients = styled(Clients)(
  ({ theme: { tablet, laptop, desktop } }) => css`
    margin-top: 30px;

    ${tablet()} {
      margin-top: 45px;
    }

    ${laptop()} {
      margin-top: 85px;
    }

    ${desktop()} {
      margin-top: 100px;
    }
  `
);

const ButtonsContainer = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-template-columns: 198px;
    grid-gap: 10px;
    margin-top: 30px;

    ${tablet()} {
      grid-template-columns: 198px 198px;
    }
  `
);

const FirstScreenButton = styled(CTAButton)`
  margin-top: 0;
`;

const FirstScreenTextContainer = styled.div(
  ({ theme: { tablet, laptop, desktop } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    ${tablet()} {
      max-width: 100%;
    }

    ${laptop()} {
      max-width: 445px;
      align-items: flex-start;
      margin-bottom: 0;
    }

    ${desktop()} {
      max-width: 500px;
    }
  `
);

export const FirstScreen = ({ btnSignUpProps = {}, ppc = false }) => (
  <StyledSection>
    <StyledContainer>
      <FirstScreenTextContainer>
        <Title ppc={ppc} />

        {ppc ? (
          <StyledSubText>
            <em>
              Uploadcare is a complete file uploading, media processing &amp; content delivery
              platform built for contemporary developers.
            </em>
          </StyledSubText>
        ) : (
          <StyledSubText>
            <em>
              Upload, store, transform, optimize, and deliver images, videos, and documents
              to&nbsp;billions of&nbsp;users.
            </em>
          </StyledSubText>
        )}

        <ButtonsContainer>
          <FirstScreenButton
            forwardedAs={Link}
            to="/accounts/signup/"
            type={ButtonTypeEnum.PRIMARY}
            {...btnSignUpProps}
          >
            Start now for free
          </FirstScreenButton>
          <FirstScreenButton
            forwardedAs={Link}
            to="/features/"
            type={ButtonTypeEnum.PRIMARY}
            data-analytics="btn_platform_0"
            ghost
          >
            Explore platform
          </FirstScreenButton>
        </ButtonsContainer>
      </FirstScreenTextContainer>

      <video
        autoPlay
        playsInline
        loop
        muted
        width="100%"
        poster="https://ucarecdn.com/46a16ca4-2a65-4c2b-8821-df1781d27c7f/uploadcare-is-easy-thumbnail.png"
      >
        <source
          src="https://ucarecdn.com/657894c0-8aa1-4e05-9e79-472cf32187ec/uploadcare-is-easy.webm"
          type="video/webm"
        />

        <source
          src="https://ucarecdn.com/57635683-2e79-4639-8491-dfaed3d0d454/uploadcare-is-easy.mp4"
          type="video/mp4"
        />
      </video>
    </StyledContainer>
    <StyledClients />
  </StyledSection>
);
