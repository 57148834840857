import React from 'react';

import { Link } from '~/src/components/Link';

import {
  Container,
  Header,
  Label,
  Logo,
  Blockquote,
  Footer,
  FooterText,
  StyledAvatar,
  StyledArrowIcon,
} from './ReviewCard.styles';

export const ReviewCard = ({
  className,
  logoSrc,
  logoAlt = '',
  label,
  quote,
  avatarSrc,
  avatarAlt,
  to,
  withBackground = true,
}) => {
  const hasLink = Boolean(to);

  return (
    <Container
      as={hasLink ? Link : undefined}
      to={to}
      className={className}
      $withBackground={withBackground}
    >
      <Header>
        <Logo src={logoSrc} alt={logoAlt} />
        {Boolean(label) && <Label>{label}</Label>}
      </Header>
      <Blockquote>“{quote}”</Blockquote>
      <Footer>
        <StyledAvatar src={avatarSrc} alt={avatarAlt} />
        {hasLink && (
          <>
            <FooterText>Read story</FooterText>
            <StyledArrowIcon />
          </>
        )}
      </Footer>
    </Container>
  );
};
