import styled, { css } from 'styled-components';
import { hideScrollbarStyles } from '../../CommonStyles';

export const CDNImageContainer = styled.code(
  ({ theme: { laptop } }) => css`
    --default-color: #ffffff;
    --tags-color: #49535d;
    --uuid-color: #ffab70;
    --operation-name-color: #b392f0;
    --operation-value-color: #b392f0;
    --file-name-color: #69e3bf;
    --tab-size: 20px;

    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
    font-weight: 400;
    font-family: var(--font-mono);
    font-size: 12px;
    letter-spacing: 0.004px;
    color: var(--default-color);
    line-height: 1.5;
    padding-bottom: 10px;
    ${hideScrollbarStyles};

    ${laptop()} {
      --tab-size: 25px;
      font-size: 14px;
    }
  `
);

export const CDNImageTags = styled.span`
  color: var(--tags-color);
`;

export const CDNImageAttribute = styled.div(
  ({ theme: { laptop } }) => css`
    display: flex;
    flex-direction: column;
    padding-left: var(--tab-size);
    line-height: 1.8;
    width: max-content;

    ${laptop()} {
      margin: 6px 0;
    }
  `
);

export const CDNImageCodeBlock = styled.div`
  width: max-content;
`;

export const CDNImageUUID = styled.span`
  color: var(--uuid-color);
`;

export const CDNImageOperationName = styled.span`
  color: var(--operation-name-color);
`;

export const CDNImageOperationValue = styled.span`
  color: var(--operation-value-color);
`;

export const CDNImageFileName = styled.span`
  color: var(--file-name-color);
`;

export const CDNImageCommonBlocksContainer = styled.div(
  ({ theme: { laptop } }) => css`
    position: relative;
    padding-right: 113px;

    ${laptop()} {
      padding-right: 175px;
    }
  `
);

export const CDNImageHintContainer = styled.div`
  --background-color: #41210b;
  --text-color: #f0a16b;
  --line-color: #5e3113;
`;

export const CDNImageOperationsHintContainer = styled(CDNImageHintContainer)`
  --line-color: #3d2073;
  --background-color: rgba(42, 15, 91, 0.7);
  --text-color: #b392f0;
`;

export const CDNImageFileNameHintContainer = styled(CDNImageHintContainer)`
  --line-color: #244f42;
  --background-color: #112f26;
  --text-color: #69e3bf;
`;

export const CDNImageHint = styled.div(
  ({ theme: { laptop } }) => css`
    padding: 6px 12px;
    border-radius: 10px;
    background-color: var(--background-color);
    height: max-content;
    width: max-content;
    box-sizing: border-box;

    ${laptop()} {
      padding: 8px 16px;
    }
  `
);

export const CDNImageHintText = styled.span(
  ({ theme: { laptop } }) => css`
    font-family: var(--font-headings);
    font-weight: 500;
    font-size: 13px;
    line-height: 1.8;
    color: var(--text-color);

    ${laptop()} {
      font-size: 14px;
    }
  `
);
